<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
        spinner="spinner" />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 pt-5>
            <span style="font-size: 25px; font-weight: bolder">EDIT NATURE CAMP</span>
          </v-flex>
          <v-flex xs11 pt-5>
            <v-card elevation="0" class="mainfont" style="color: #000000; font-size: 16px; font-weight: 500">
              <v-layout wrap justify-center>
                <v-flex>
                  <v-layout pa-4 wrap justify-start>
                    <v-flex xs4 pt-6>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field style="border-radius: 0%" color="#283e51" outlined label="Camp Name"
                          background-color="white" :rules="campnameRules" v-model="list.campName"></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field style="border-radius: 0%" color="#283e51" outlined background-color="white"
                          label="Total Members" type="number" class="inputPrice" v-model="list.totalMembers"
                          hide-details></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field style="border-radius: 0%" color="#283e51" outlined background-color="white"
                          label="Rate" type="number" v-model="list.rate" class="inputPrice" hide-details></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete outlined style="border-radius: 0px" label="District" color="black"
                          :items="itemArray2" v-model="list.district" item-text="name" item-value="name"
                          item-color="#FF1313" hide-details clearable class="custom-autocomplete mainfont">
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete outlined style="border-radius: 0%" label="Circle" color="black" :items="circle"
                          v-model="list.circleid" item-text="officeName" item-value="_id" item-color="#FF1313"
                          hide-details clearable class="custom-autocomplete mainfont">
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete style="border-radius: 0%" outlined label="Division" color="black"
                          :items="division" v-model="list.divisionid" item-text="officeName" item-value="_id"
                          item-color="#FF1313" hide-details clearable class="custom-autocomplete mainfont">
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs4 pt-6>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete style="border-radius: 0%" outlined label="Range" color="black" :items="range"
                          v-model="list.rangeid" item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                          clearable class="custom-autocomplete mainfont">
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>
                    <!-- <v-flex xs4 pt-6 pl-4>
                      <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field clearable outlined readonly hide-details="auto" v-model="formattedStartDate"
                            v-bind="attrs" background-color="white" v-on="on"
                            @click:clear="startDate = null; formattedStartDate = null" style="color: black">
                            <template v-slot:append>
                              <v-icon color="black">mdi-chevron-down</v-icon>
                            </template>
                            <template v-slot:label>
                              <span class="custom-label-text" style="color: black; font-size: 14px">Block Date
                                From:</span>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" color="#13736f" @change="updateFormattedStartDate"
                          :min="minDate"></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs4 pt-6 pl-4>
                      <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field clearable outlined readonly hide-details="auto" v-model="formattedEndDate"
                            v-bind="attrs" background-color="white" v-on="on"
                            @click:clear="endDate = null; formattedEndDate = null" style="color: black">
                            <template v-slot:append>
                              <v-icon color="black">mdi-chevron-down</v-icon>
                            </template>
                            <template v-slot:label>
                              <span class="custom-label-text" style="color: black; font-size: 14px">Block Date To:</span>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" color="#13736f" @change="updateFormattedEndDate"
                          :min="minDate"></v-date-picker>
                      </v-menu>
                    </v-flex> -->



                    <v-flex xs12 pt-8>
                      <span>Add Caption</span><br />
                      <vue-editor v-model="list.caption"></vue-editor>
                    </v-flex>

                    <v-flex xs12 text-right pt-9 pb-10>
                      <v-btn @click="editCamp()" dark width="200px" color="#283e51">
                        <span style="color: white; font-size: 14px">SAVE CHANGES</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  data() {
    return {
      name: null,
      barlist: [],
      userProfileTabs: null,
      subtab: null,
      menuFrom: false,
      menuTo: false,
      startDate: null,
      endDate: null,
      minDate: new Date().toISOString().substr(0, 10),

      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,
      id: this.$route.query.id,
      itemArray2: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      editdailog: false,

      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      limit: 10,
      noOfRooms: null,
      accName: null,
      maxGuestPerRoom: null,
      caption: null,
      rangeid: null,
      circleid: null,
      range: [],
      divisionid: null,
      msg: null,
      currentPage: 1,
      division: [],
      showSnackBar: false,
      circle: [],
      dialog2: false,
    };
  },
  watch: {
    circleid() {
      this.getDivision();
    },

    divisionid() {
      this.getRange();
    },
  },
  computed: {
    campnameRules() {
    return [
      (v) => !!v || "Please enter the Camp name",
      (v) => /^[a-zA-Z0-9\s]+$/.test(v) || "Camp Name must contain only alphanumeric characters",
      ];
    },
},

  mounted() {
    this.getList();
    this.getCircle();
  },

  methods: {
    updateFormattedStartDate() {
      this.formattedStartDate = this.startDate ? this.formatDate(this.startDate) : null;
    },

    updateFormattedEndDate() {
      this.formattedEndDate = this.endDate ? this.formatDate(this.endDate) : null;
    },

    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('en-GB', options);
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/camp",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: this.id,
        },
      })
        .then((response) => {
          this.list = response.data.data;

          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    editCamp() {
      axios({
        method: "POST",
        url: "/edit/naturecamp",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: this.id,

          circleid: this.list.circleid,
          campName: this.list.campName,
          district: this.list.district,
          totalMembers: this.list.totalMembers,
          rate: this.list.rate,

          divisionid: this.list.divisionid,
          rangeid: this.list.rangeid,
          caption: this.list.caption,
          blockDateFrom: this.startDate,
          blockDateTo: this.endDate,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {},
      })
        .then((response) => {
          this.circle = response.data.data;

          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getDivision() {
      this.appLoading = true;
      const selectedCircle = this.circle.find((c) => c._id === this.circleid);
      if (selectedCircle) {
        const path = selectedCircle.path;
        axios({
          method: "post",
          url: "/get/divsions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            //   circleid: this.circleid,
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.division = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },

    getRange() {
      this.appLoading = true;
      // Find the selected division based on divisionid
      const selectedDivision = this.division.find(
        (d) => d._id === this.divisionid
      );
      if (selectedDivision) {
        // Extract the path from the selected division
        const path = selectedDivision.path;

        axios({
          method: "post",
          url: "/get/range",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            divisionid: this.divisionid,
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.range = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>